import React from "react"
import PageTransition from "../components/PageTransition"
import Seo from "../components/Seo/Seo"
import ArticleListingItem from "../components/ArticleListingItem"
import ArticleListing from "../components/ArticleListing"
import { graphql } from "gatsby"
import "./index.sass"

const IndexPage = (props) => {

	const { data } = props
	const posts = data.posts.edges
	const { node: firstPost } = data.featuredPost.edges[0]

	return (

		<PageTransition {...props}>

			<Seo 
				title="Deviant Robot: Meandering adventures into interesting ideas."
				titleTemplate={`%s`}
				description="The world is full of interesting ideas and Deviant Robot will serve them up to you with a light side salad of sass and charm."
				pathname="/"/>

			<div className="homepage">

				<h1 className="visuallyhidden">Deviant Robot: Meandering adventures into interesting ideas.</h1>

				<div className="featured-post">

					<ArticleListingItem featured {...firstPost.fields} {...firstPost.frontmatter} timeToRead={firstPost.timeToRead} />

				</div>

				<ArticleListing skipPostID={firstPost.id} posts={posts} />

			</div>

		</PageTransition>

	)

}

export const query = graphql`fragment indexListing on MarkdownRemarkEdge {
	node {
		id
		timeToRead
		...FieldsQuery
		frontmatter {
			abstract
			title
			hasVideo
			isNsfw
			tileImage {
				childImageSharp {
					gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
				}
			}
			featuredImage {
				childImageSharp {
					gatsbyImageData(width: 700, placeholder: BLURRED, layout: CONSTRAINED, formats: [AUTO, WEBP, AVIF])
				}
			}
			articlePublicationDate(formatString: "MMMM DD, YYYY")
		}
	}
}
`

export const articleAuthorQuery = graphql`

	fragment articleAuthorQuery on ArticleAuthorYaml {

		id
		name
		bio
		avatar {
			childImageSharp {
				gatsbyImageData(width: 360, placeholder: TRACED_SVG, layout: CONSTRAINED)
			}
		}

	}

`

export const FieldsQuery = graphql`

	fragment FieldsQuery on MarkdownRemark {

		fields {
			slug
			category
			collection
		}

	}

`

export const pageQuery = graphql`

	query {

		featuredPost: allMarkdownRemark(

			limit: 1

			filter: {
				fields: {
					category: {nin: ["Recommended"]}
				}
				frontmatter: {
					draft: {ne: true}
					noFeature: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___articlePublicationDate]
			}

		) {
			edges {
				...indexListing
			}
		}

		posts: allMarkdownRemark(

			limit: 21

			filter: {
				fields: {
					category: {nin: ["Recommended"]}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___articlePublicationDate]
			}

		) {
			edges {
				...indexListing
			}
		}
	}

`

export default IndexPage
